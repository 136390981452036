<template>
  <b-table-simple
    hover
    caption-top
    caption-bottom
    responsive
    bordered
  >
    <b-thead>
      <b-tr>
        <b-th>YENİLEME DURUMU</b-th>
        <b-th class="text-center">
          ORAN
        </b-th>
        <b-th class="text-center">
          ADET
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr
        v-for="(item,key) in report.data"
        :key="key"
      >
        <b-td>{{ item.title }}</b-td>
        <b-td class="text-center">
          %{{ item.percent }}
        </b-td>
        <b-td class="text-center">
          {{ item.count }}
        </b-td>
      </b-tr>
    </b-tbody>
    <b-tfoot>
      <b-tr>
        <b-th colspan="2">
          Toplam
        </b-th>
        <b-th class="text-center">
          {{ report.totals.total }}
        </b-th>
      </b-tr>
    </b-tfoot>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'

export default {
  name: 'TableRenewal',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
  },
  computed: {
    report() {
      return this.$store.getters['rentalRenewals/getRenewalsReport']
    },
  },
}
</script>

<style scoped>

</style>
