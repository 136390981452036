<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col
              xs="12"
              md="6"
            >
              <b-form-group label="Başlangıç Tarihi">
                <b-form-datepicker
                  v-model="sdate"
                  v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                  locale="tr"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              md="6"
            >
              <b-form-group label="Bitiş Tarihi">
                <b-form-datepicker
                  v-model="edate"
                  v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                  locale="tr"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="text-center"
            >
              <b-button
                variant="primary"
                :disabled="!sdate || !edate"
                @click="getReport"
              >
                <FeatherIcon icon="BarChartIcon" />
                <span class="align-middle">Raporu Görüntüle</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card v-if="report.data.length > 0">
          <b-row>
            <b-col
              xs="12"
              md="6"
            >
              <table-renewal />
            </b-col>
            <b-col
              xs="12"
              md="6"
            >
              <apex-donut-chart2
                :data-labels="report.chartData.labels"
                :data-series="report.chartData.series"
                :data-colors="report.chartData.colors"
                :height="500"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormDatepicker,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import TableRenewal from '@/views/Reports/Rental/RenewalsReport/TableRenewal'
// eslint-disable-next-line import/extensions
import ApexDonutChart2 from '@/views/Reports/apex-chart/ApexDonutChart2'

export default {
  name: 'DigitalReport',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormDatepicker,
    TableRenewal,
    ApexDonutChart2,
  },
  data() {
    return {
      sdate: null,
      edate: null,
    }
  },
  computed: {
    report() {
      return this.$store.getters['rentalRenewals/getRenewalsReport']
    },
  },
  methods: {
    getReport() {
      this.$store.dispatch('rentalRenewals/renewalsReport', {
        sdate: this.sdate,
        edate: this.edate,
      })
    },
  },
}
</script>
<style scoped></style>
