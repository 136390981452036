<template>
  <div>
    <vue-apex-charts
      v-if="series.length > 0"
      type="donut"
      :height="height"
      width="100%"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: [
    'dataLabels',
    'dataSeries',
    'dataColors',
    'height',
  ],
  data() {
    return {
      series: [],
      chartOptions: {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
          // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  formatter(val) {
                  // eslint-disable-next-line radix
                    return `${parseInt(val)} Adet`
                  },
                },
                total: {
                  show: false,
                },
              },
            },
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
    }
  },
  watch: {
    dataSeries(val) {
      if (val) {
        this.setLabels()
        this.setColors()
      }
    },
  },
  created() {
    this.setLabels()
    this.setColors()
  },
  methods: {
    setLabels() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels: this.dataLabels,
        },
      }
      this.series = this.dataSeries
    },
    setColors() {
      if (this.dataColors) {
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            colors: this.dataColors,
          },
        }
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
